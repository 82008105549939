<main class="u_overflow-hidden h-100">
  @if (!joined) {
    <div class="flex-row">
      <div class="join-panel aligner p-4">
        <div class="join-content">
          <img src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg" width="100" class="mb-3">
          <h4>Enter Code to Share Screen</h4>
          <p>Enter the 6-character code displayed on your digital signage screen.</p>
          <div class="form-group required pt-3">
            <code-input
              #codeInput
              [codeLength]="6"
              [isCharsCode]="true"
              autocapitalize="characters"
              (codeCompleted)="codeCompleted($event)"
            />
            <p [shown]="screenSharingService.channelError" class="help-block validation-error text-danger pt-4">{{screenSharingService.channelError}}</p>
          </div>

          <button class="btn btn-primary mt-4" (click)="join()"> {{ screenSharingService.connecting ? 'Connecting...' : 'Start Sharing'}}</button>
        </div>
      </div>
      <div class="background-panel"></div>
    </div>
  }
  @else {
    <div class="container pt-5">
      <h4>Screen Share</h4>
      <div class="flex-row flex-wrap align-start gap-5 mt-5">
        <div class="share-panel">
          <div class="flex-row space-between pt-1">
            <div class="font-weight-bold mb-4">You are sharing your screen</div>
            <button class="btn btn-primary ml-5" (click)="screenSharingService.reset()">Stop Sharing</button>
          </div>
        </div>
        <div class="preview-panel">
          <div class="font-weight-bold mb-4">Share Preview</div>
          <video #preview id="preview" autoplay muted width="640" height="360"></video>
        </div>
      </div>
    </div>
  }
</main>
