import { Component, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { ScreenSharingService } from '../../services/screen-sharing.service';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription } from 'rxjs';

@Component({
  selector: 'screen-sharing',
  templateUrl: './screen-sharing.component.html',
  styleUrls: ['./screen-sharing.component.scss']
})
export class ScreenSharingComponent implements AfterViewInit, OnDestroy, OnInit {
  joinCode = '';
  joinedSubscription: Subscription;
  joined: boolean;

  @ViewChild('codeInput') codeInput: CodeInputComponent;
  @ViewChild('preview', { static: false }) set preview(content: ElementRef) {
    if (content) {
      this.screenSharingService.preview = content;
    }
  }

  constructor(
    public screenSharingService: ScreenSharingService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.joinedSubscription = this.screenSharingService.joinedSubject.subscribe((value) => {
      this.joined = value;
      if (!value) {
        setTimeout(() => {
          this.codeInput.focusOnField(0);
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.codeInput.focusOnField(0);
  }

  ngOnDestroy(): void {
    this.screenSharingService.reset();
    this.joinedSubscription.unsubscribe();
  }

  join() {
    this.screenSharingService.join(this.joinCode, this.changeDetector);
  }

  codeCompleted(code: string) {
    this.joinCode = code.toUpperCase();
  }
}
