<form #loginForm="ngForm" id="forms.loginForm" role="form" name="forms.loginForm" class="u_margin-sm-top" autocomplete="on" novalidate>
  <div class="form-group" [ngClass]="{'has-error': (loginForm.submitted && username.invalid)}" show-errors>
    <label class="control-label">Work Email:</label>
    <div [shown]="disableUsername">{{ credentials.username }}</div>
    <input
      #username="ngModel"
      type="email"
      class="form-control"
      placeholder="example@email.com"
      id="username"
      name="username"
      [(ngModel)]="credentials.username"
      required
      focus-me="true"
      autocomplete="email"
      [shown]="!disableUsername"
      [pattern]="EMAIL_REGEX"
      (blur)="checkUsername()">
    <p class="text-danger" [shown]="loginForm.submitted && username.invalid">
      Please enter an Email
    </p>
  </div>

  <password-input
    [shown]="passwordRequired && !invalidRequest"
    #password="ngModel"
    name="password"
    [(ngModel)]="credentials.password"
    label="Password:"
    [showPasswordMeter]="isSignUp"
    minlength="{{ isSignUp ? 8 : 0}}"
    [required]="passwordRequired">
  </password-input>

  @if (ssoEnabled && !usePassword) {
    <div class="my-5">
      <streamline-icon name="checkmark" width="14" height="14"></streamline-icon> Single Sign-on is enabled for your account
    </div>
  }
  @if (!invalidRequest) {
    <button id="sign-in-button" class="btn btn-primary btn-hg btn-block" type="submit" form="forms.loginForm" (click)="submitForm.emit(loginForm)">
      @if (isSignUp) {
        <span>{{ joinAccount ? 'Join' : 'Start My Free 14-Day Trial' }}</span>
      }
      @else {
        <span>{{ passwordRequired ? 'Sign In' : 'Continue' }}</span>
      }
    </button>
  }
  @else {
    <button
      id="resend-email-button"
      class="btn btn-primary btn-hg btn-block mt-4"
      type="button"
      (click)="handleInvalidRequest.emit()"
      [disabled]="username.invalid"
    >
      Resend Email
    </button>
  }

  @if (ssoEnabled) {
    <div class="my-4 text-center">
      <a class="madero-link" href="#" (click)="alternateLogin()">Continue with {{ usePassword ? 'Single Sign-on' : 'password' }}</a>
    </div>
  }
</form>

<div class="my-4 flex-row">
  <div class="border-bottom w-50"></div>
  <span class="u_nowrap px-2">Or</span>
  <div class="border-bottom w-50"></div>
</div>

<!-- GOOGLE API -->
<button class="btn btn-provider-auth btn-google-auth btn-hg btn-block" id="sign-in-google-link" (click)="triggerGoogleLogin()">
  <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg">
  <div class="mx-auto">Sign in with Google</div>
</button>
