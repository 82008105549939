import { Injectable } from '@angular/core';

import * as $ from 'jquery';
import * as jQuery from 'jquery';
import * as _ from 'lodash';
import { TemplateEditorUtilsService } from './template-editor-utils.service';
import { AttributeDataService } from './attribute-data.service';
import { TemplateEditorService } from './template-editor.service';
import { Subject } from 'rxjs';
import { FeaturesService } from 'src/app/components/plans/features.service';

export type PlaylistComponentDirective = {
  type: string;
  element: Element;
  show: () => void;
  onBackHandler?: () => void;
};

export type PlaylistComponentInfo = {
  type: string;
  id?: string;
  label?: string;
  directive?: PlaylistComponentDirective;
};

export type PlaylistComponentItem = {
  type: string;
  title: string;
  subtitle?: string;
  icon?: string;
  panel?: string;
  visual?: boolean;
  userCanAdd?: boolean,
  playUntilDone?: boolean;
  requireRole?: string;
  requireFeature?: string;
  defaultAttributes?: { [key: string]: string|number|boolean };
  options?: { [key: string]: string|number|boolean };
};

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  static readonly COMPONENTS_MAP: { [key: string]: PlaylistComponentItem } = {
    'rise-branding-colors': {
      type: 'rise-branding-colors',
      icon: 'palette',
      panel: '.branding-colors-container',
      title: 'Color Settings',
    },
    'rise-branding': {
      type: 'rise-branding',
      icon: 'ratingStar',
      panel: '.branding-component-container',
      title: 'Brand Settings',
    },
    'rise-override-brand-colors': {
      type: 'rise-override-brand-colors',
      icon: 'palette',
      title: 'Override Brand Colors',
    },
    'rise-data-counter': {
      type: 'rise-data-counter',
      icon: 'hourglass',
      title: 'Counter'
    },
    'rise-data-financial': {
      type: 'rise-data-financial',
      icon: 'financial',
      title: 'Financial'
    },
    'rise-data-table': {
      type: 'rise-data-table',
      icon: 'datatable',
      title: 'Data Table'
    },
    'rise-text': {
      type: 'rise-text',
      icon: 'text',
      title: 'Text',
      visual: true,
      userCanAdd: true,
      defaultAttributes: {
        fontsize: 100,
        multiline: true,
        verticalalign: 'middle',
        horizontalalign: 'center',
        textalign: 'center'
      }
    },
    'rise-image-logo': {
      type: 'rise-image-logo',
      icon: 'circleStar',
      panel: '.image-component-container',
      title: 'Logo Settings'
    },
    'rise-folder': {
      type: 'rise-folder',
      icon: 'riseFolder',
      panel: '.folder-component-container',
      title: 'Folder of Images & Videos',
      playUntilDone: true,
      visual: true,
    },
    'rise-playlist': {
      type: 'rise-playlist',
      icon: 'playlist',
      panel: '.rise-playlist-container',
      title: 'Playlist',
      playUntilDone: true,
      userCanAdd: true,
    },
    'rise-playlist-item': {
      type: 'rise-playlist-item',
      icon: 'embedded-template',
      panel: '.playlist-item-container',
      title: 'Playlist Item'
    },
    'rise-playlist-item-selector': {
      type: 'rise-playlist-item-selector',
      icon: 'playlist',
      title: 'Add Playlist Item'
    },
    'rise-youtube': {
      type: 'rise-youtube',
      icon: 'video',
      panel: '.youtube-component-container',
      title: 'YouTube',
      playUntilDone: true,
      visual: true,
      userCanAdd: true,
    },
    'rise-image': {
      type: 'rise-image',
      icon: 'image',
      panel: '.image-component-container',
      title: 'Image',
      playUntilDone: true,
    },
    'rise-video': {
      type: 'rise-video',
      icon: 'video',
      panel: '.video-component-container',
      title: 'Video',
      playUntilDone: true
    },
    'rise-media-selector': {
      type: 'rise-media-selector',
      title: 'Select a Source'
    },
    'rise-template-share-settings': {
      type: 'rise-template-share-settings',
      title: 'Save Presentation As Template'
    },
    'rise-presentation-selector': {
      type: 'rise-presentation-selector',
      icon: 'embedded-template',
      panel: '.presentation-selector-container',
      title: 'Presentation',
      visual: true
    },
    'rise-data-rss': {
      type: 'rise-data-rss',
      icon: 'rss',
      title: 'RSS'
    },
    'rise-schedules': {
      type: 'rise-schedules',
      icon: 'schedule',
      title: 'Schedules'
    },
    'rise-web-page': {
      type: 'rise-web-page',
      icon: 'webPage',
      title: 'Web Page',
      visual: true,
      userCanAdd: true,
    },
    'rise-slides': {
      type: 'rise-slides',
      icon: 'slides',
      title: 'Google Slides',
      visual: true,
      userCanAdd: true,
      playUntilDone: true,
      defaultAttributes: {
        src: ''
      },
    },
    'rise-canva': {
      type: 'rise-canva',
      icon: 'canva',
      title: 'Canva',
      visual: true,
      userCanAdd: true,
    },
    'rise-html': {
      type: 'rise-html',
      icon: 'html',
      title: 'HTML Embed',
      visual: true,
      userCanAdd: true,
    },
    'rise-stock-library': {
      type: 'rise-stock-library',
      icon: 'stockLibrary',
      title: 'Stock Library',
      panel: '.stock-library-container',
      visual: true,
      userCanAdd: true,
      requireFeature: FeaturesService.FEATURE_ASSET_LIBRARY
    },
    'rise-powerbi': {
      type: 'rise-powerbi',
      icon: 'powerBI',
      title: 'Power BI',
      visual: true,
      userCanAdd: true,
      requireFeature: FeaturesService.FEATURE_POWER_BI
    },
    'rise-document': {
      type: 'rise-document',
      icon: 'document',
      title: 'Document'
    },
    'rise-storage-selector': {
      type: 'rise-storage-selector',
      icon: 'riseStorage',
      panel: '.storage-selector-container',
      title: 'Rise Storage',
    },
    'rise-time-date': {
      type: 'rise-time-date',
      icon: 'time',
      title: 'Time and Date'
    },
    'rise-data-twitter': {
      type: 'rise-data-twitter',
      icon: 'twitter',
      title: 'Twitter'
    },
    'rise-data-weather': {
      type: 'rise-data-weather',
      icon: 'sun',
      title: 'Weather'
    },
    'rise-data-calendar': {
      type: 'rise-data-calendar',
      icon: 'calendar',
      title: 'Calendar'
    }
  };

  static readonly DocumentItem: PlaylistComponentItem = {
    type: 'rise-folder',
    title: 'Document',
    subtitle: 'pdf, docx, pptx, xlsx',
    icon: 'document',
    options: {
      fileType: 'document'
    }
  };

  static readonly GooglePhotosItem: PlaylistComponentItem = {
    type: 'rise-folder',
    title: 'Google Photos',
    icon: 'googlePhotos',
    options: {
      fileType: 'google-photos-album'
    }
  };

  static readonly PowerpointItem: PlaylistComponentItem = {
    type: 'rise-document',
    title: 'PowerPoint',
    icon: 'powerpoint',
    options: {
      fileType: 'powerpoint'
    },
    requireRole: 'sa'
  }

  static get COMPONENTS_ARRAY() {
    return _.values(ComponentsService.COMPONENTS_MAP);
  }

  static get PLAYLIST_COMPONENTS() {
    const list = ComponentsService.COMPONENTS_ARRAY.filter(item => item.visual);
    const index = list.findIndex(item => item.type === 'rise-folder');
    const documentItem = _.cloneDeep(ComponentsService.DocumentItem);
    const powerpointItem = _.cloneDeep(ComponentsService.PowerpointItem);
    list.splice(index + 1, 0, documentItem);
    list.splice(index + 2, 0, powerpointItem);


    const index2 = list.findIndex(item => item.type === 'rise-slides');
    let googlePhotosItem = _.cloneDeep(ComponentsService.GooglePhotosItem);

    list.splice(index2 + 1, 0, googlePhotosItem);

    return list;
  }

  static get USER_COMPONENTS_ARRAY() {
    const list = ComponentsService.COMPONENTS_ARRAY.filter(item => item.userCanAdd);

    const index = list.findIndex(item => item.type === 'rise-text');
    list.splice(index + 1, 0, {
      type: 'rise-folder',
      icon: 'image',
      title: 'Image',
      options: {
        fileType: 'image',
        singleFile: true
      }
    },{
      type: 'rise-folder',
      icon: 'video',
      title: 'Video',
      options: {
        fileType: 'video',
        singleFile: true
      }
    }, {
      type: 'rise-folder',
      icon: 'riseFolder',
      panel: '.folder-component-container',
      title: 'Folder of Images & Videos',
      playUntilDone: true,
      options: {
        fileType: 'folder',
        singleFile: true
      }
    });

    const index2 = list.findIndex(item => item.type === 'rise-web-page');
    let documentItem = _.cloneDeep(ComponentsService.DocumentItem);
    let powerpointItem = _.cloneDeep(ComponentsService.PowerpointItem);

    documentItem.options.singleFile = true;

    list.splice(index2 + 1, 0, documentItem);
    list.splice(index2 + 2, 0, powerpointItem);

    const index3 = list.findIndex(item => item.type === 'rise-slides');
    let googlePhotosItem = _.cloneDeep(ComponentsService.GooglePhotosItem);
    list.splice(index3 + 1, 0, googlePhotosItem);

    return list;
  }

  selected = null;
  showAttributeList = true;
  directives = {};
  pages = [];

  panelIcon;
  panelTitle;

  private updateComponentSource = new Subject<string>();
  componentValueUpdated$ = this.updateComponentSource.asObservable();

  constructor(private templateEditorUtils: TemplateEditorUtilsService,
    private attributeDataService: AttributeDataService) {
      this.reset();
    }


    reset() {
      this.selected = null;
      this.showAttributeList = true;
      this.directives = {};
      this.pages = [];
    }

    updateComponentValue (value: string) {
      this.updateComponentSource.next(value);
    }

    registerDirective(directive) {
      if (!(directive.element instanceof jQuery)) {
        directive.element = $(directive.element);
      }

      directive.element.hide();
      this.directives[directive.type] = directive;

      _.defaults(directive, ComponentsService.COMPONENTS_MAP[directive.type], {
        panel: '.attribute-editor-component'
      });

      if (directive.onPresentationOpen) {
        directive.onPresentationOpen();
      }
    }

    _getDirective(component) {
      if (!component) {
        return null;
      } else if (component.directive) {
        return component.directive;
      } else if (this.directives[component.type]) {
        return this.directives[component.type];
      } else {
        return null;
      }
    }

    _getSelectedDirective() {
      var component = this.selected;

      return this._getDirective(component);
    }

    editComponent(component?, options?) {
      const directive = this._getDirective(component);

      this.selected = component;

      this.showNextPage(component);

      if (directive && directive.show) {
        directive.show(options);
        if (!options?.highlightClicked) {
          setTimeout(() => {
            this.editComponentInPreview(component.id);
          });
        }
      }

      this._showAttributeList(false);
    }

    onBackButton() {
      this.highlightComponent(null);

      var directive = this._getSelectedDirective();

      if (!directive || !directive.onBackHandler || !directive.onBackHandler()) {
        this.showPreviousPage();
      }

      this.editComponentInPreview(this.selected?.id);
    }

    // Private
    backToList() {
      var directive = this._getSelectedDirective();

      if (directive && directive.element) {
        directive.element.hide();
      }

      this.resetPanelHeader();

      this.selected = null;
      this.pages = [];

      this._showAttributeList(true);
    }

    getComponentIcon(component?) {
      var directive = this._getDirective(component);

      if (directive && directive.getIcon) {
        return directive.getIcon(component.id) || directive.icon;
      } else if (directive && directive.icon) {
        return directive.icon;
      } else {
        return 'blank';
      }
    }

    getComponentTitle(component?) {
      var directive = this._getDirective(component);

      if (directive && directive.getLabel) {
        return directive.getLabel(component.id) || directive.title;
      } else if (this.panelTitle) {
        return this.panelTitle;
      } else if (component && component.label) {
        return component.label;
      } else if (directive && directive.title) {
        return directive.title;
      } else {
        return '';
      }
    }

    getComponentName(component) {
      var directive = this._getDirective(component);

      if (component && component.label) {
        return component.label;
      } else if (directive && directive.getName) {
        return directive.getName(component.id) || directive.title;
      } else if (directive) {
        return directive.title;
      } else {
        return '';
      }
    }

    postMessageToTemplate(message) {
      var iframe = window.document.getElementById('template-editor-preview') as HTMLIFrameElement;
      iframe.contentWindow.postMessage(JSON.stringify(message), TemplateEditorService.HTML_TEMPLATE_DOMAIN);
    }

    highlightComponent(componentId) {
      var message = {
        type: 'highlightComponent',
        value: componentId
      };
      this.postMessageToTemplate(message);
    }

    editComponentInPreview(componentId: string) {
      var message = {
        type: 'editComponentInPreview',
        value: componentId,
      };
      this.postMessageToTemplate(message);
    }

    isHeaderBottomRuleVisible(component) {
      var directive = this._getDirective(component);

      return directive && directive.isHeaderBottomRuleVisible ?
        directive.isHeaderBottomRuleVisible() : true;
    }

    getCurrentPage() {
      return this.pages.length > 0 ? this.pages[this.pages.length - 1] : null;
    }

    showNextPage(newPage) {
      var currentPage = this.getCurrentPage();

      this.pages.push(newPage);
      this._swapToLeft(currentPage, newPage);
    }

    showPreviousPage() {
      var currentPage = this.pages.length > 0 ? this.pages.pop() : null;
      var previousPage = this.getCurrentPage();

      if (!previousPage) {
        this.backToList();
      } else {
        this.selected = previousPage;

        this._swapToRight(currentPage, previousPage);
      }
    }

    resetPanelHeader() {
      this.setPanelIcon(null);
      this.setPanelTitle(null);
    }

    setPanelIcon(panelIcon) {
      this.panelIcon = panelIcon;
    }

    setPanelTitle(panelTitle) {
      this.panelTitle = panelTitle;
    }

    editHighlightedComponent(componentId: string) {

      if (!componentId) {
        this.backToList();
        return;
      }

      let component = this.attributeDataService.getComponent(componentId);

      if (component) {
        if (this.selected) {
          this.backToList();
        }

        if (component.userComponent) {
          component = {id: component.id, type: component.tagName};
        }

        this.editComponent(component, {highlightClicked: true});
      }
    }

    _showAttributeList(value) {
        this.showAttributeList = value;
    }

    _removeAnimationClasses(element) {
      element.removeClass('attribute-editor-show-from-right');
      element.removeClass('attribute-editor-show-from-left');
    }

    _showElement(component, direction, delay?) {
      var directive = this._getDirective(component);
      var element = directive && directive.panel && this.templateEditorUtils.findElement(directive.panel, directive.element);

      if (directive && directive.element) {
        directive.element.show();
      }

      if (!element) {
        return;
      }

      this._removeAnimationClasses(element);
      element.addClass('attribute-editor-show-from-' + direction);

      setTimeout( () => {
        element.show();
      }, delay || 0);
    }

    _hideElement(component, delay?) {
      var directive = this._getDirective(component);
      var selectedDirective = this._getSelectedDirective();

      var element = directive && directive.panel && this.templateEditorUtils.findElement(directive.panel, directive.element);

      if (directive && directive.element && !directive.element.is(selectedDirective.element)) {
        directive.element.hide();
      }

      if (!element) {
        return;
      }

      setTimeout( () => {
        element.hide();
      }, delay || 0);
    }

    _swapToLeft(swappedOutSelector, swappedInSelector) {
      this._showElement(swappedInSelector, 'right');
      this._hideElement(swappedOutSelector);
    }

    _swapToRight(swappedOutSelector, swappedInSelector) {
      this._showElement(swappedInSelector, 'left');
      this._hideElement(swappedOutSelector);
    }

}
